import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      <span className="heading-meta">About Me</span>
                      <h2 className="colorlib-heading">Who Am I?</h2>
                      <p>
                        I am a software enginner since 2017, I've been working
                        on tech, from small projects until present on multiarea
                        projects. Also I love cinema and I write some lectures
                        to film. I develop mobile apps, backend, frontend,
                        design BD and deploy everywhere. I consider myself like
                        a person who solves problems, not just do some backend
                        or frontend stuff.
                      </p>
                      <p>
                        I have started reflecting my ideas creating a community
                        called "Code MX" where I teach and share tech knowledge,
                        to me technology is a windows where everybody can walk
                        and make a difference.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <span className="heading-meta">What I do?</span>
                <h2 className="colorlib-heading">
                  Here are some of my expertise
                </h2>
              </div>
            </div>
            <div className="row row-pt-md">
              <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                  <span className="icon">
                    <i className="icon-bulb" />
                  </span>
                  <div className="desc">
                    <h3>Frontend Development </h3>
                    <p>
                      I have experience building websites using JS, HTML, CSS,
                      <b>React JS </b>, Material UI and Boostrap. Also, I push
                      nowdays to improve the apps using microfrontends
                      architecture.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                  <span className="icon">
                    <i className="icon-phone3" />
                  </span>
                  <div className="desc">
                    <h3>Backend Development</h3>
                    <p>
                      I like create logic applications/microservices with
                      Node/Express JS, Laravel/PHP and Spring Boot/Java.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                  <span className="icon">
                    <i className="icon-data" />
                  </span>
                  <div className="desc">
                    <h3>Databases</h3>
                    <p>
                      I've worked designing databases with MySQL, Mongo DB,
                      PosgreSQL and SQL Server.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                  <span className="icon">
                    <i className="icon-data" />
                  </span>
                  <div className="desc">
                    <h3>Mobile Tech</h3>
                    <p>
                      I usually create hibrids apps with <b>React Native </b>.
                      But I like Native Android with Java/Kotlin or use Swift to
                      create iOS Apps.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                  <span className="icon">
                    <i className="icon-layers2" />
                  </span>
                  <div className="desc">
                    <h3>Cloud Computing</h3>
                    <p>
                      I've used and deployed projects with <b>Azure</b>, IBM
                      Cloud, Heroku, Netlify.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                  <span className="icon">
                    <i className="icon-phone3" />
                  </span>
                  <div className="desc">
                    <h3>Main/Trending Tech</h3>
                    <p>
                      As a full stack developer. I use Git, gitflow, firebase,
                      pusher, google maps. I've worked with Scrum and new tech
                      like <b>Blockchain</b>, helping startups to adapt and
                      includes the web 3 paradigm. That's why I consider the
                      web3 a new way to design things. And talking about
                      Quality, I like testing on frontend and backend using{" "}
                      <b>Jest</b>,<b> Testing Library </b>and <b>JUnit</b>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
