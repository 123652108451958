import React, { Component } from 'react'

export default class Projects extends Component {
  render() {
    return (
      <div>
				<section className="colorlib-work" data-section="projects">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">My Work</span>
								<h2 className="colorlib-heading animate-box">I've worked on these projects:</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project" style={{backgroundImage: 'url(images/taxis.jfif)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://vigsoft.tech/" target="_blank">Taxis Lleida Catalunya</a></h3>
											<span>Web platform and Mobile App Android/IOS</span>
											<p className="icon">
												<span><a href="https://vigsoft.tech/" target="_blank"><i class="fas fa-info"></i></a></span>
				
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
								<div className="project" style={{backgroundImage: 'url(images/solvento.png)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://vigsoft.tech/" target="_blank">Solvento MX</a></h3>
											<span>Mobile App Android/IOS</span>
											<p className="icon">
												<span><a href="https://vigsoft.tech/" target="_blank"><i class="fas fa-info"></i></a></span>
									
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
								<div className="project" style={{backgroundImage: 'url(images/checkking.webp)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://vigsoft.tech/" target="_blank">Checkking App</a></h3>
											<span>Mobile App Android/IOS</span>
											<p className="icon">
												<span><a href="https://vigsoft.tech/" target="_blank"><i class="fas fa-info"></i></a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInBottom">
								<div className="project" style={{backgroundImage: 'url(images/nido.png)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://vigsoft.tech/" target="_blank">Nido de Pymes</a></h3>
											<span>Web platform and Mobile App Android/IOS</span>
											<p className="icon">
												<span><a href="https://vigsoft.tech/" target="_blank"><i class="fas fa-info"></i></a></span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project" style={{backgroundImage: 'url(images/winapp.png)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="https://vigsoft.tech/" target="_blank">WinApp</a></h3>
											<span>Graphic, Logo</span>
											<p className="icon">
												<span><a href="https://vigsoft.tech/" target="_blank"><i class="fas fa-info"></i></a></span>
				
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
								<div className="project" style={{backgroundImage: 'url(images/feelrait.png)'}}>
									<div className="desc">
										<div className="con">
											<h3><a href="work.html">FeelRait</a></h3>
											<span>Mobile App Android/IOS</span>
					
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</section>
      </div>
    )
  }
}
