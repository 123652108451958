import React, { Component } from "react";

export default class Timeline extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="timeline">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <span className="heading-meta">My soft career</span>
                <h2 className="colorlib-heading animate-box">Timeline</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Software Engineer III - Axity / Walmart CAM{" "}
                          <span>
                            January 2023 - Present <b>(Full Time)</b>
                          </span>
                        </h2>
                        <p>
                          Design, propose and develop solutions for different
                          areas like retail and financial services in many
                          countries from LATAM. Using tech stack with Javascript
                          or native tech like android. Leader the frontend team
                          implementing new architecture on performance and
                          microfrontends.
                        </p>
                      </div>
                    </div>
                  </article>

                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Mobile Developer Lead - Avera MX{" "}
                          <span>
                            November 2021 - December 2022 <b>(Full Time)</b>
                          </span>
                        </h2>
                        <p>
                          Manage, innovate and propose improvement proposals in
                          the company's processes through the development of
                          native mobile applications with technology such as
                          Swift and Kotlin for iOS/Android.
                        </p>
                      </div>
                    </div>
                  </article>

                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Mobile Developer Lead - Finvero{" "}
                          <span>
                            May 2021 - November 2021 <b>(Full Time)</b>
                          </span>
                        </h2>
                        <p>
                          Development of mobile applications focused on the
                          fintech sector, refactoring and code updating.
                          Management and support in mobile development team.
                          Analysis of requirements and organization of tasks for
                          the update or satisfaction of customers.
                        </p>
                      </div>
                    </div>
                  </article>

                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-2">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Developer Student Lead - Google{" "}
                          <span>
                            {" "}
                            June 2021 - June 2022 <b>(Part Time)</b>
                          </span>
                        </h2>
                        <p>
                          Management and organization of technological events,
                          support and mentoring in technological issues to
                          university students from different schools and
                          teaching of different technologies such as we, mobile
                          and cloud computing with the support of Google.
                        </p>
                      </div>
                    </div>
                  </article>

                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Project Lead Developer - Vigsoft{" "}
                          <span>
                            December 2018- December 2022 <b>(Part Time)</b>
                          </span>
                        </h2>
                        <p>
                          Development and organization of technology-based
                          projects projects for various clients Development of
                          mobile apps DB development and server configuration.
                          Consulting startups on new stach techs like
                          blockchain, microservices and microfrontends.
                        </p>
                      </div>
                    </div>
                  </article>

                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInTop"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Web Development Teacher - EDESH Group
                          <span>
                            {" "}
                            February 2018 - June 2021 <b>(Sundays)</b>{" "}
                          </span>
                        </h2>
                        <p>
                          I teach all subjects related to relational DB
                          management, web site creation, mobile applications and
                          project planning, mobile applications and project
                          planning. I also teach mathematics. My schedule is on
                          Sundays.
                        </p>
                      </div>
                    </div>
                  </article>

                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInTop"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-7">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Full Stack Developer - Solvento MX
                          <span>
                            {" "}
                            July 2019 - January 2020 <b>(Part Time)</b>{" "}
                          </span>
                        </h2>
                        <p>
                          Development of customer satisfaction survey platforms,
                          bots and AI algorithms for Captcha recognition,
                          project management, hosting management and database
                          modeling Captcha recognition, project management,
                          hosting management and database modeling, as well as
                          their deployment in the and database modeling, as well
                          as its deployment in the Microsoft Azure cloud.
                        </p>
                      </div>
                    </div>
                  </article>

                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Full Stack Developer / Consultant - Rabech 3G{" "}
                          <span>
                            {" "}
                            August 2018 <b>(Freelance)</b>
                          </span>
                        </h2>
                        <p>
                          Consulting and development of mobile/web applications
                          for different clients. Relational database design and
                          project relational database design and project
                          planning using agile methodologies and modern
                          technologies.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className="timeline-entry begin animate-box"
                    data-animate-effect="fadeInBottom"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none"></div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
